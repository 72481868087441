
.aboutContainer{
    width:          100%;
    display:        grid;

    grid-template-columns:  auto 500px 150px 500px auto;
    grid-template-rows:     auto;
}

.aboutContainer *{
    z-index:        50;
}

.profilePic{
    grid-column:    2 / 3;
    grid-row:       1;
    align-self:     center;
    width:          100%;
}

.textBox{
    grid-column:    4 / 5;
    grid-row:       1;
    text-align:     right;
    align-self:     center;
    justify-self:   end;
    margin-top:     60px;
    width:          100%;
}

.textBox p{
    margin-top:     30px;
}

.logoAnimation{
    grid-column:    2 / 3;
    grid-row:       1;
    width:          70px;
    align-self:     start;
    justify-self:   end;

    transform: translate(-20px, 150px) rotateZ(10deg);

}



@media (max-width: 1400px) {

    .aboutContainer{
        grid-template-columns:  auto 350px 100px 350px auto;
    }

}

@media (max-width: 1000px) {

    .aboutContainer{
        grid-template-columns:  auto 600px auto;
        grid-template-rows:     fit-content(50%) 50%;
    }

    .profilePic{
        grid-column:    2 / 3;
        grid-row:       1 / 2;
        justify-self:   center;
        align-self: center;
    }

    .textBox{
        grid-column:    2 / 3;
        grid-row:       2 / 3;
        text-align:     start;
        align-self:     start;
    }

    .logoAnimation{
        grid-column:    2 / 3;
        grid-row:       1 / 2;
        width: 80px;
        transform: translate(-30px, 160px) rotateZ(10deg);
    }

}

@media (max-width: 760px) {

    .aboutContainer {
        grid-template-columns:  10% 80% 10%;
        grid-template-rows: fit-content(50%) 50%;
    }

    .logoAnimation{
        width: 50px;
        margin-top: 30%;
        margin-right: 5%;
        transform: rotateZ(10deg);
    }


}
