*{
  margin:     0;
  padding:    0;
  text-decoration: none;
}

*, *::before, *::after{
  box-sizing: border-box;
}

html, body{
  width:      100%;
  height:     100%;
}

#root{
  background-color:   #ffffff;
  font-family:        'Poppins', Verdana, sans-serif;
  padding:            0;
  color:              #000000;
}

h1{
  font-family: 'Lora', serif;
  font-size:   65px;
  color:       #000000;
}

h2{
  font-size:  24px;
  color:      #000000;
  font-weight: normal;
}

h3{
  font-family: 'Lora', serif;
  font-size:   50px;
  color:       #000000;
}

p{
  font-size:  18px;
  color:      #000000;
}

h5{
  font-size:  18px;
}

h6{
  font-size:  12px;
  color:      #000000;
}


.linkIcon{
  display:    inline-block;
  transform:  translate(0, 5px);
}

@media (max-width: 1400px) {

  h1{
    font-size: 50px;
  }

  h2{
    font-size:  20px;
  }

  h3{
    font-size: 35px;
  }

  p{
    font-size: 15px;
  }

  h5{
    font-size: 15px;
  }


}

@media (max-width: 1000px) {

}

@media (max-width: 760px) {
  h1{
    font-size: 40px;
  }

  h2{
    font-size: 18px;
  }

  h3{
    font-size: 25px;
  }

  p{
    font-size: 15px;
  }

  h5{
    font-size: 12px;
  }

}
