.visualsContainer{
    width:      100%;
    height:     100%;
    z-index:    10;

    position:   fixed;
    top:        0;
    left:       0;
}

.overlay{
    width:      100%;
    height:     100%;
    z-index:    15;

    position:   fixed;
    top:        0;
    left:       0;

    backdrop-filter:            blur(3px);
    -webkit-backdrop-filter:    blur(3px);
}


@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .overlay {
        backdrop-filter:            blur(3px);
        -webkit-backdrop-filter:    blur(3px);
    }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .overlay {
        background-color: rgba(255, 255, 255, 0.2);
    }
}