
.p_header_container{
    width:          100%;
    height:         fit-content;
    margin-bottom:  100px;

    display:                grid;
    grid-template-columns:  76px auto 1248px auto 76px;
    grid-auto-rows:         60px 100px 702px fit-content(300px) 60px auto;
    row-gap:                20px;
}

.p_header_container a p:hover{
    animation: colorWheel 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.p_header_container *{
    z-index: 10;
}

.p_headline{
    grid-column:    3 / 4;
    grid-row:       2 / 3;
    justify-self:   start;
    align-self:     center;
}

.p_year{
    grid-column:    3 / 4;
    grid-row:       2 / 3;
    justify-self:   end;
    align-self:     end;
}

.p_teaser{
    grid-column:    3 / 4;
    grid-row:       3 / 4;
    align-self:     center;
}

.p_teaser img{
    width: 100%;
}

.p_teaser iframe{
    aspect-ratio: 16/9;
}

.p_shortInfo{
    grid-column:    3 / 4;
    grid-row:       4 / 5;
}

.p_shortInfo h5{
    display: inline-block;
    color: #000000;
}

.p_shortInfo h5:hover{
    animation: colorWheel 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.p_shortInfo svg{
    margin-right: 5px;
    transform: translate(0, 6px);
}

.p_backIcon{
    width:          40px;
    height:         40px;
    z-index:        80;

    position:       fixed;
    bottom:         30px;
    left:           18px;
}

.p_backIcon_frame{
    width:  100%;
    height: 100%;
    display: grid;


    transition:     height 0.5s, width 0.5s;
}

.p_backIcon:hover .p_backIcon_frame{
    width:  30px;
    height: 30px;
}

#p_circle {
    width: 100%;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    animation:                  rotation 10s;
    animation-timing-function:  linear;
    animation-iteration-count:  infinite;
}

@keyframes rotation {
    0%   {transform: rotateZ(0deg)}
    100% {transform: rotateZ(360deg)}
}

#p_arrow{
    width:      70%;
    fill:       #ffffff;

    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-self: center;
    align-self: center;
}



.p_listOverview{
    grid-column:    3 / 4;
    grid-row:       6 / 7;

    display:                grid;
    grid-template-columns:  repeat(4, 312px);
}

.p_listOverview h2{
    font-family:    'Lora', serif;
    margin-bottom:  10px;
}

.p_listOverview div{
    padding-right: 20px;
    padding-left: 20px;
    border-left: 2px solid #000000;
}

.p_entry_1{
    grid-column: 1 / 2;
}

.p_entry_2{
    grid-column: 2 / 3;
}

.p_entry_3{
    grid-column: 3 / 4;
}

.p_entry_4{
    grid-column: 4 / 5;
}


@media (max-width: 1400px) {
    .p_header_container{
        grid-template-columns:  76px auto 848px auto 76px;
        grid-auto-rows:         60px 100px 477px fit-content(300px) 60px auto;
    }

    .p_listOverview{
        grid-template-columns:  repeat(4, 212px);
    }
}

@media (max-width: 1000px) {
    .p_header_container{
        grid-template-columns:  76px auto 608px auto 76px;
        grid-auto-rows:         60px 100px 342px fit-content(300px) 60px auto;
    }

    .p_listOverview{
        grid-template-columns:  repeat(4, 152px);
    }

    .p_listOverview div{
        padding-left: 10px;
    }

}

@media (max-width: 760px) {

    .p_header_container {
        grid-template-columns:  10% 80% 10%;
        grid-auto-rows:         60px 100px fit-content(342px) fit-content(300px) 60px auto;
        row-gap:                15px;
    }

    .p_headline, .p_year, .p_teaser, .p_shortInfo, .p_listOverview {
        grid-column: 2 / 3;
    }

    .p_listOverview{
        grid-template-columns:  50% 50%;
        grid-template-rows:     fit-content(250px) fit-content(250px);
        row-gap:                30px;
    }

    .p_listOverview div{
        padding-right: 10px;
    }

    .p_entry_1, .p_entry_2{
        grid-row:    1 / 2;
    }

    .p_entry_3, .p_entry_4{
        grid-row:    2 / 3;
    }

    .p_entry_3{
        grid-column: 1 / 2;
    }

    .p_entry_4{
        grid-column: 2 / 3;
    }
}