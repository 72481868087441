#p_teaser_container_all{
    width:          100%;
    z-index:        10;
    margin-top:     60px;
    margin-bottom:  60px;
}

#p_teaser_container_all * {
    z-index: 10;
}

.p_teaser_container_1{
    display:                grid;
    grid-template-columns:  76px auto 1248px auto 76px;
    grid-template-rows:     702px;
}

.p_teaser_container_2{
    display:                grid;
    grid-template-columns:  76px auto 616px 16px 616px auto 76px;
    grid-template-rows:     346px;
}

.p_teaser_container_3{
    display:                grid;
    grid-template-columns:  76px auto 405px 16px 405px 16px 405px auto 76px;
    grid-template-rows:     228px;
}




#p_teaser_container_all img{
    width:      100%;
    height:     100%;
    object-fit: cover;
}

.picture1{
    grid-column:    3 / 4;
    grid-row:       1 / 2;
    aspect-ratio:   16/9;
}

.picture2{
    grid-column:    5 / 6;
    grid-row:       1 / 2;
    aspect-ratio:   16/9;
}

.picture3{
    grid-column:    7 / 8;
    grid-row:       1 / 2;
    aspect-ratio:   16/9;
}

@media (max-width: 1400px) {
    .p_teaser_container_1{
        grid-template-columns:  100%;
        grid-template-rows:     fit-content(787px);
    }

    .p_teaser_container_2{
        grid-template-columns:  auto 16px auto;
        grid-template-rows:     fit-content(390px);
    }

    .p_teaser_container_3{
        grid-template-columns:  auto 16px auto 16px auto;
        grid-template-rows:     fit-content(257px);
    }

    .picture1{
        grid-column: 1 / 2;
    }

    .picture2{
        grid-column: 3 / 4;
    }

    .picture3{
        grid-column: 5 / 6;
    }


}

@media (max-width: 1000px) {

    .p_teaser_container_3{
        grid-template-columns:  auto 16px auto;
        grid-template-rows:     fit-content(277px) 16px fit-content(563px);
    }

    .picture3{
        grid-column:    1 / 4;
        grid-row:       3 / 4;
    }

}

@media (max-width: 760px) {
    .p_teaser_container_1{
        grid-template-columns:  100%;
        grid-template-rows:     fit-content(342px);
    }

    .p_teaser_container_2{
        grid-template-columns:  100%;
        grid-template-rows:     fit-content(342px) 16px fit-content(342px);
    }

    .p_teaser_container_3{
        grid-template-columns:  100%;
        grid-template-rows:     fit-content(342px) 16px fit-content(342px) 16px fit-content(342px);
    }

    .picture1{
        grid-column:    1 / 2;
    }

    .picture2{
        grid-column:    1 / 2;
        grid-row:       3 / 4;
    }

    .picture3{
        grid-column:    1 / 2;
        grid-row:       5 / 6;
    }

}