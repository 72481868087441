
.imDa_Grid{
    display:        grid;
    width:          100%;
    grid-template-columns: 10% 80% 10%;
    padding-top:    130px;
    padding-bottom: 50px;


}

.imprint_container{
    grid-column:    2 / 3;
    justify-self:   center;
    z-index:        2;
}

.imDa_Grid h1{
    margin-bottom:  20px;
}


.imDa_Grid h2{
    margin-top:     20px;
}

.imDa_Grid h3{

}

.imDa_background{
    position:   absolute;
    right:      0;
    top:        0;
    width:      100%;
    height:     100%;
    z-index:    1;

    opacity:    0.07;

    background-position:    right;
    background-repeat:      no-repeat;
    background-size:        cover;
}
