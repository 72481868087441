
.p_Background{
    position:   fixed;
    top:        0;
    left:       0;
    width:      100%;
    z-index:   0;
}

.p_background_blur{
    width:  100%;
    height: 100%;

    background:                 rgba(255, 255, 255, 0.35);
    backdrop-filter:            blur(40px);
    -webkit-backdrop-filter:    blur(40px);

}

.p_gradient1{
    position:       fixed;
    top:            0;
    left:           0;
    width:          50%;
    aspect-ratio:   1;

    animation:                  move1 50s;
    animation-timing-function:  ease-in-out;
    animation-iteration-count:  infinite;
}

.p_gradient2{
    position:       fixed;
    right:          0;
    bottom:         0;
    width:          50%;
    aspect-ratio:   1;

    animation:                  move2 60s;
    animation-timing-function:  ease-in-out;
    animation-iteration-count:  infinite;
}

.p_gradient3{
    position:       fixed;
    right:          40%;
    bottom:         10%;
    width:          50%;
    aspect-ratio:   1;

    animation:                  move3 40s;
    animation-timing-function:  ease-in-out;
    animation-iteration-count:  infinite;
}

@keyframes move1 {
    0%   { width: 40%; top: -5%; left: -5%}
    50%  { width: 80%; top: 50%; left: 30%}
    100% { width: 40%; top: -5%; left: -5%}
}

@keyframes move2 {
    0%   { width: 80%;  right: -10%; bottom: -10%}
    30%  { width: 30%;  right: 40%; bottom: 40%}
    60%  {              right: 0; bottom: 80%}
    100% { width: 80%;  right: -10%; bottom: -10%}
}

@keyframes move3 {
    0%   { width: 80%;  right: 30%; bottom: 50%}
    20%  { width: 30%}
    50%  {              right: 10%; bottom: 5%}
    80%  {              right: 40%; bottom: 40%}
    100% { width: 80%;  right: 30%; bottom: 50%}
}