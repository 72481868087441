
.entryContainer{
    width:      100%;
    height:     180px;
    z-index:    60;

    display:                grid;
    grid-template-columns:  76px 150px 60px 560px auto 76px;
    grid-template-rows:     30px 35px 35px 80px;
}

.year{
    grid-column:    2 / 3;
    grid-row:       1 / 2;
    justify-self:   end;
}

.keys{
    margin-top:     5px;
    grid-column:    2 / 3;
    grid-row:       2 / 3;
    justify-self:   end;
    text-align:     end;
}

.gradient{
    z-index:        40;
    grid-column:    3 / 4;
    grid-row:       1 / 4;
    width:          3px;
    height:         95%;
    justify-self:   center;

    opacity:    0;
    transition: opacity 1s;
}


.keys p{
    display:        inline-block;
    font-size:      12px;
}

.entryContainer h1{
    grid-column:    4 / 6;
    grid-row:       1 / 3;
    align-self:     end;

    color:                  transparent;
    transition:             color 1s;
}


/* Find out if its Firefox or not */
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .entryContainer h1{
        -webkit-text-stroke:    0.1px #000000;
    }
}
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .entryContainer h1{
        -webkit-text-stroke:    2px #000000;
    }
}


.projectLink{
    grid-column:    4 / 5;
    grid-row:       3;
    align-self:     end;
}



.projectLink h5{
    display:        inline-block;
    margin-left:    10px;
    color:          transparent;
    transition:         color 1s;
}

.projectLink svg *{
    fill:               transparent;
    transition:         fill 1s;
}

.entryContainer:hover .projectLink h5{
   color: black;
}

.entryContainer:hover .projectLink svg *{
    fill: black;
}

.entryContainer:hover h1{
    color: black;
}

.entryContainer:hover .gradient{
    opacity: 1;
}


#mobileHover .projectLink h5{
    color: black;
}

#mobileHover .projectLink svg *{
    fill: black;
}

#mobileHover h1{
    color: black;
}

#mobileHover .gradient{
    opacity: 1;
}



@media (max-width: 1400px) {

    .entryContainer{
        grid-template-columns:  76px 100px 60px 430px auto 76px;
    }

}

@media (max-width: 1000px) {
    .entryContainer{
        height: inherit;
        grid-template-columns:  76px 100px 60px auto 76px;
        grid-template-rows:     30px 35px 35px fit-content(100px) 200px;
    }

    .infoText{
        grid-column: 4 / 5;
        grid-row: 4 / 5;
    }

    .projectLink{
        grid-row: 5 / 6;
        margin-top: 20px;
        align-self: start;
    }

    /* Find out if its Firefox or not */
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .entryContainer h1{
            -webkit-text-stroke:    0.5px #000000;
        }
    }

}

@media (max-width: 760px) {
    .entryContainer{
        grid-template-columns:  10% auto 10%;
        grid-template-rows:     35px fit-content(100px) 35px fit-content(100px) 200px;
    }

    .year{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        justify-self: start;
    }

    .entryContainer h1{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }

    .keys{
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        justify-self: start;
    }

    .infoText{
        grid-column: 2 / 3;
        grid-row: 4 / 5;
    }

    .projectLink{
        grid-column: 2 / 3;
    }

    .gradient{
        grid-column: 1 / 2;
    }

}

