.dialogBackground{
    width:      100%;
    height:     100%;
    position:   fixed;
    top:        0;
    left:       0;
    z-index:    199;

    background: rgba(255,255,255,10%);
}

.dialogContainer{
    width:      560px;
    height:     388px;
    z-index:    200;

    position:   fixed;
    top:        50%;
    left:       50%;
    transform:  translate(-280px, -194px);

    display:                 grid;
    grid-template-columns:  60px 440px 60px;
    grid-template-rows:     38px 250px 100px;

    /* From https://css.glass */
    background:                 rgba(255, 255, 255, 0.35);
    border-radius:              16px;
    box-shadow:                 0 4px 30px rgba(0, 0, 0, 0.1);
    border:                     1px solid rgba(255, 255, 255, 0.3);
}

/* is browser supporting blur? */
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .dialogContainer {
        backdrop-filter:            blur(11.3px);
        -webkit-backdrop-filter:    blur(11.3px);
    }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .dialogContainer{
        background-color: rgba(255, 255, 255, 0.9);
    }
}

.dialogContainer h1{
    font-size:      65px;
    grid-column:    2 / 3;
    grid-row:       2 / 3;
    align-self:     center;
    justify-self:   start;
}

.dialogContainer h5{
    font-size:      18px;
    word-break:     break-all;
    display:        contents;
}

.dialogContainer a{
    color: #000000;
}

.dialogContainer a:hover{
    animation:                  colorWheel 3s;
    animation-timing-function:  linear;
    animation-iteration-count:  infinite;
}

.content{
    grid-column:    2 / 3;
    grid-row:       3 / 4;
    align-self:     center;
    justify-self:   end;
    padding-right:  30px;
    text-align:     end;
}

.closeDialog{
    width:          24px;
    height:         24px;
    grid-column:    1 / 4;
    grid-row:       1 / 2;
    justify-self:   end;
    align-self:     end;
    margin-right:   16px;
}

.closeDialog:hover{
    cursor: pointer;
}

.dialogArrow{
    width:          24px;
    height:         24px;
    transform:      rotate(180deg);
    grid-column:    2 / 3;
    grid-row:       3 / 4;
    align-self:     center;
    justify-self:   end;
}


.particles{
    grid-column:    2 / 3;
    grid-row:       2 / 3;
    align-self:     end;
    justify-self:   end;
    width:          100%;
    height:         100%;
}

.hearts{
    opacity:    0;
    position:   absolute;
    animation:  hearts 3s ease-in infinite;
}

@keyframes hearts {
    0% {
        opacity: 0;
        transform:translate(0, 0%);
    }
    20% {
    opacity: 0.8;
        transform:translate(0, -20%);
    }
    100% {
        opacity: 0;
        transform:translate(0, -800%);
    }
}

@media (max-width: 760px) {

    .dialogContainer{
        width:  280px;
        height: 288px;
        grid-template-columns:  30px 220px 30px;
        grid-template-rows:     38px 150px 100px;

        transform: translate(-140px, -144px);
    }

    .dialogContainer h1{
        font-size: 40px;
    }

    .dialogContainer h5{
        font-size: 15px;
    }

    @keyframes hearts {
        0% {
            opacity: 0;
            transform:translate(0, 0%);
        }
        20% {
            opacity: 0.8;
            transform:translate(0, -20%);
        }
        100% {
            opacity: 0;
            transform:translate(0, -500%);
        }
    }
}