
.p_video{
    width:          100%;
    margin-bottom:  60px;
    margin-top:     60px;
    z-index:        10;

    display:                grid;
    grid-template-columns:  76px auto 1248px auto 76px;
    grid-template-rows:     40px 702px;
}

.p_video * {
    z-index: 10;
}

.p_video iframe{
    aspect-ratio: 16/9;


    grid-column:    3 / 4;
    grid-row:       2 / 3;
}

.p_video h2{
    grid-column:    3 / 4;
    grid-row:       1 / 2;
    justify-self:   start;
    align-self:     start;
}

@media (max-width: 1400px) {
    .p_video{
        grid-template-columns:  76px auto 848px auto 76px;
        grid-template-rows:     40px 477px;
    }
}

@media (max-width: 1000px) {
    .p_video {
        grid-template-columns:  76px auto 608px auto 76px;
        grid-template-rows:     40px 342px;
    }
}


@media (max-width: 760px) {
    .p_video {
        grid-template-columns:  10% 80% 10%;
        grid-template-rows:     30px fit-content(342px);
    }

    .p_video iframe{
        grid-column: 2 / 3;
    }

    .p_video h2{
        grid-column: 2 / 3;
    }

}