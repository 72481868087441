.menuContainer{
    position:   absolute;
    top:        0;
    left:       0;
    z-index:    101;
    height:     100%;
    width:      100%;

    display:                grid;
    grid-template-columns:  auto 500px 150px 500px auto;
    grid-template-rows:     30% auto 30px;

    /* From https://css.glass */
    background:                 rgba(255, 255, 255, 0.35);
    box-shadow:                 0 4px 30px rgba(0, 0, 0, 0.1);
    border:                     1px solid rgba(255, 255, 255, 0.3);

}

/* is browser supporting blur? */
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .menuContainer {
        backdrop-filter:            blur(11.3px);
        -webkit-backdrop-filter:    blur(11.3px);
    }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .menuContainer{
        background-color: rgba(255, 255, 255, 0.9);
    }
}

.menuContainer *{
    opacity: 0;
}

#show{
    animation:                  slideOpen 1s;
    animation-fill-mode:        forwards;
    animation-timing-function:  ease;
}

#show *{
    animation:                  appear 0.6s;
    animation-fill-mode:        forwards;
    animation-timing-function:  ease;
    animation-delay:            0.8s;
}

#hide{
    animation:                  slideClose 1s;
    animation-fill-mode:        forwards;
    animation-timing-function:  ease;
    animation-delay:            0.2s;
}

#hide * {
    animation:                  disappear 0.6s;
    animation-fill-mode:        forwards;
    animation-timing-function:  ease;
}

.bigTalk{
    grid-column:    3 / 4;
    grid-row:       2 / 3;
    transition:     transform 0.5s;
}

.bigTalk:hover{
    transform:      scale(0.9,0.9);
}

.menuContainer h5{
    display:        inline-block;
    margin-left:    10px;
    color:          #000000;
}

.navProjects{
    justify-self:   start;
    grid-column:    2 / 5;
    grid-row:       2 / 3;
    transition:     transform 1s;
}

.navPersonal{
    justify-self:   end;
    grid-column:    4 / 5;
    grid-row:       2 / 3;
    transition:     transform 1s;
}

.navProjects:hover{
    transform: scale(0.8, 0.8);
}

.navPersonal:hover{
    transform: scale(0.8, 0.8);
}

.imprint{
    grid-column:    1 / 3;
    grid-row:       3 / 4;
    margin-left:    40px;
    align-self:     center;
}

.imprint * {
    display: inline-block;
}

.copyright{
    grid-column:    4 / 6;
    grid-row:       3 / 4;
    justify-self:   end;
    margin-right:   40px;
    align-self:     center;
}


@keyframes slideOpen {
    0%   { width: 0}
    100% { width: 100%}
}

@keyframes slideClose {
    0%   { width: 100%}
    100% { width: 0}
}

@keyframes appear {
    0%   { opacity: 0}
    100% { opacity: 100}
}

@keyframes disappear {
    0%   { opacity: 100}
    100% { opacity: 0}
}

@media (max-width: 1400px) {

    .menuContainer{
        grid-template-columns:  auto 350px 100px 350px auto;
    }


}

@media (max-width: 1000px) {

    .menuContainer {
        grid-template-columns:  auto 250px 100px 250px auto;
        grid-template-rows:     60px 30% 20% 30% auto 30px;
        background-color:       rgba(255, 255, 255);
    }


    .navProjects{
        grid-row:   2 / 3;
        align-self: center;
    }

    .navPersonal{
        grid-row:   4 / 5;
        align-self: center;
    }

    .bigTalk{
        align-self:     center;
        justify-self:   center;
        grid-column:    2 / 5;
        grid-row:       3 / 4;
        width:          200px;
    }

    .imprint{
        grid-row: 6 / 7;
    }

    .copyright{
        grid-row: 6 / 7;
    }

}

@media (max-width: 760px) {

    .menuContainer {
        grid-template-columns:  10% auto 10%;
    }

    .navProjects{
        grid-column: 2 / 3;
    }

    .navPersonal{
        grid-column: 2 / 3;
    }

    .bigTalk{
        align-self:     center;
        justify-self:   center;
        grid-column:    1 / 4;
        width:          100px;
    }

    .imprint{
        grid-column: 1 / 4;
        margin-left: 5px;
    }

    .copyright{
        grid-column: 1 / 4;
        margin-right: 5px;
    }


}

