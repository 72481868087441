
.listContainer{
    width:          100%;
    margin-top:     150px;
    margin-bottom:  200px;
}

.listContainer *{
    z-index:        50;
}

.midLine{
    position:       relative;
    height:         80px;
    width:          fit-content;
    margin-left:    286px;
    text-align:     left;
}




.overlay{
    transition:     opacity 0.3s;
}

.info{
    width:      500px;
    height:     fit-content;

    position:   fixed;
    right:      0;
    bottom:     18px;
    z-index:    50;

    transition: opacity 0.5s;
}

.info h5{
    height:         fit-content;
    margin-right:   60px;
    margin-top:     8px;
    padding-right:  0px;

    text-align:     right;
    font-weight:    normal;
}

.circle{
    position:       absolute;
    width:          8px;
    height:         8px;
    right:          34px;
    top:            0;

    background:     black;
    box-shadow:     0 0 1px 1px rgba(0, 0, 0, 0.8);
    animation:      pulse 2s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.8);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
}

.positionPictureContainer{
    display: grid;
    width: 100%;
    height: 50%;

    position: fixed;
    top: 20%;

    grid-template-columns:  900px auto 76px;
}

.pictureContainer{
    display:                grid;
    grid-template-columns:  50% 24% 26%;
    grid-template-rows:     50% 24% 26%;
    width: 500px;
    height: 500px;


    aspect-ratio: 1;

    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: center;

    transition: opacity 0.5s;
}

.img1{
    grid-column:    1 / 3;
    grid-row:       1 / 3;
    width:          0;
    justify-self:   end;

    background-position:    center;
    background-size:        cover;
    background-repeat:      no-repeat;
    box-shadow:             15px -2px 29px -15px rgba(0,0,0,0.51);
    transition:             width 0.5s;
}

.img2{
    grid-column:    2 / 4;
    grid-row:       2 / 4;
    width:          0;
    justify-self:   start;


    background-position:    center;
    background-size:        cover;
    background-repeat:      no-repeat;
    box-shadow:             15px -2px 29px -15px rgba(0,0,0,0.4);
    transition:             width 0.5s;
}

#openImg1{
    width: 100%;
}

#openImg2{
    width: 100%;
}



@media (max-width: 1400px) {

    .positionPictureContainer{
        grid-template-columns:  720px auto 76px;
    }

    .info{
        width: 300px;
    }

    .midLine{
        margin-left: 236px;
    }

    .pictureContainer{
        width:  400px;
        height: 400px;
    }
}

@media (max-width: 1200px) {
    .pictureContainer{
        width:  280px;
        height: 280px;
    }
}


@media (max-width: 760px) {
    .midLine{
        margin-left: 10%;
    }

}

@media (max-height: 800px) {
    .pictureContainer{
        width:  unset;
        height: 80%;
    }
}



