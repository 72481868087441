.footerContainer{
    width:  100%;
    height: 800px;

    display: grid;
    grid-template-columns:  18px auto 500px 150px 500px auto 18px;
    grid-template-rows:     90% 9% 1%;
    margin-bottom:          18px;
}

.footerContainer *{
    z-index:    50;
    color:      #000000;
}

.footerContainer h6{
    display: inline-block;
}

.footerContainer span{
    display: inline-block;
}

.footerContainer a{
    display: inline-block;
}

.whitespace{
    grid-column:    3 / 7;
    grid-row:       1 / 2;
    align-self:     center;

}

.whitespace h5{
    margin-left: 5px;
}


.leftSide{
    grid-column:    2 /4;
    grid-row:       2 / 3;

    align-self:     end;
}

.middle{
    grid-column:    3 / 6;
    grid-row:       2 / 3;

    width:          150px;
    justify-self:   center;
    align-self:     end;
}

.rightSide{
    grid-column:    5 / 7;
    grid-row:       2 / 3;
    justify-self:   end;
    align-self:     end;
}

.footerContainer span:hover{
    cursor:     pointer;
    animation:  colorWheel 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.whitespace h5:hover{
    animation: colorWheel 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}


@media (max-width: 1400px) {

    .footerContainer{
        grid-template-columns:  18px auto 350px 100px 350px auto 18px;
    }


}

@media (max-width: 1000px) {

    .footerContainer{
        grid-template-columns:  18px auto 250px 100px 250px auto 18px;
    }

}

@media (max-width:  760px) {

    .footerContainer{
        grid-template-columns:  10% 40% 40% 10%;
        grid-template-rows:     auto 6% 6% 6% 1%;
    }

    .whitespace{
        grid-column:    2 / 5;
    }

    .middle{
        grid-column:    2 / 4;
        grid-row:       2 / 3;
    }

    .leftSide{
        grid-column:    1 / 5;
        grid-row:       4 / 5;
        margin-left:    5px;
    }

    .rightSide{
        grid-column:    2 / 4;
        grid-row:       3 / 4;
        justify-self:   center;
        align-self:     start;
    }

}