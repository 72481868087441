.p_text_pic_always *{
    z-index:    10;
}

.p_text_pic_always{
    margin-bottom:  150px;
    margin-top:     150px;
}

.p_text_pic_content h3{
    margin-bottom: 15px;
}

.p_text_pic_content h5{
    display: inline-block;
    color: #000000;
}

.p_text_pic_content h5:hover{
    animation: colorWheel 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.p_text_pic_content svg{
    transform:      translate(0, 6px);
    margin-right:   5px;
}


.p_text_picture_container_L{
    width:          100%;
    z-index:        10;

    display:                grid;
    grid-template-columns:  76px auto 416px 100px 416px auto 76px;
    grid-template-rows:     fit-content(200px) 312px fit-content(200px);
}

.p_text_picture_container_L img{
    grid-column:    3 / 4;
    grid-row:       2 / 3;
    width:          100%;
    aspect-ratio:   4/3;
}

.p_text_picture_container_L .p_text_pic_content{
    grid-column:    5 / 6;
    grid-row:       1 / 4;
    text-align:     right;
    align-self:     center;
}







.p_text_picture_container_R{
    width:          100%;
    z-index:        10;

    display:                grid;
    grid-template-columns:  76px auto 416px 100px 416px auto 76px;
    grid-template-rows:     fit-content(200px) 312px fit-content(200px);
}

.p_text_picture_container_R img{
    grid-column:    5 / 6;
    grid-row:       2 / 3;
    width:          100%;
    aspect-ratio:   4/3;
}

.p_text_picture_container_R .p_text_pic_content{
    grid-column:    3 / 4;
    grid-row:       1 / 4;
    text-align:     left;
    align-self:     center;
}



@media (max-width: 1400px) {
    .p_text_picture_container_L{
        grid-template-columns:  76px auto 416px 52px 380px auto 76px;
        grid-template-rows:     fit-content(200px) 312px fit-content(200px);
    }

    .p_text_picture_container_R{
        grid-template-columns:  76px auto 380px 52px 416px auto 76px;
        grid-template-rows:     fit-content(200px) 312px fit-content(200px);
    }
}


@media (max-width: 1000px) {
    .p_text_picture_container_L{
        grid-template-columns:  76px auto 296px 52px 260px auto 76px;
        grid-template-rows:     fit-content(200px) 222px fit-content(200px);
    }

    .p_text_picture_container_R{
        grid-template-columns:  76px auto 260px 52px 296px auto 76px;
        grid-template-rows:     fit-content(200px) 222px fit-content(200px);
    }
}

@media (max-width: 760px) {

    .p_text_picture_container_L{
        grid-template-columns:  10% 80% 10%;
        grid-template-rows:     fit-content(222px) 16px fit-content(222px);
    }

    .p_text_picture_container_R{
        grid-template-columns:  10% 80% 10%;
        grid-template-rows:     fit-content(222px) 16px fit-content(222px);
    }

    .p_text_picture_container_L img, .p_text_picture_container_R img{
        grid-column:    2 / 3;
        grid-row:       3 / 4;
    }

    .p_text_picture_container_L .p_text_pic_content, .p_text_picture_container_R .p_text_pic_content{
        grid-column:    2 / 3;
        grid-row:       1 / 2;
        justify-self:   start;
        align-self:     start;
        text-align:     start;
    }

    .p_text_pic_always{
        margin-top:     100px;
        margin-bottom:  100px;
    }
}