
.navContainer{
    height:     60px;
    width:      100%;
    position:   fixed;
    top:        0;
    left:       0;
}

.navContainer, #logo, .menuButton, #smallTalk{
    z-index: 110;
}

#fullSize{
    height:     100%;
}


.logo_frame{
    position:       absolute;
    height:         24px;
    width:          40px;
    top:            18px;
    left:           18px;

    display:        grid;
}

#logo{
    transition:     height 0.4s;
    height:         24px;

    grid-column:   1 / 1;
    grid-row:      1 / 1;
    justify-self:  center;
    align-self:    center;
}

.logo_frame:hover #logo{
    height: 20px;
}

.menuButton{
    position:   absolute;
    height:     24px;
    width:      40px;
    top:        18px;
    right:      18px;
    display:    grid;
}

.menuButton_frame{
    height:     24px;
    transition: height 0.4s;

    grid-column:   1 / 1;
    grid-row:      1 / 1;
    justify-self:  center;
    align-self:    center;
}

.menuButton:hover .menuButton_frame{
    height: 20px;
}

.menuButton:hover{
    cursor: pointer;
}

.navTalk{
    height:     60px;
    width:      60px;
    position:   absolute;
    top:        70px;
    right:      8px;
    display:    grid;
}

.navTalk_frame{
    width:      60px;
    height:     60px;
    transition: height 0.3s, width 0.3s;

    grid-column:    1 / 1;
    grid-row:       1 / 1;
    justify-self:   center;
    align-self:     center;
}

.navTalk:hover .navTalk_frame{
    height: 50px;
    width:  50px;
}



@media (max-width: 760px) {

    .navTalk{
        display: none;
    }

    .navContainer{
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }


    /* is browser supporting blur? */
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .navContainer {
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
        }
    }

    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        .navContainer {
            background-color: rgba(255, 255, 255, 0.9);
        }
    }

}


