
.startScreen{
    width:          100%;
    height:         100%;
    display:        grid;
    grid-template-columns:  auto 500px 150px 500px auto;
    grid-template-rows:     60px 40% 45% auto;
}

.startScreen *{
    z-index:        50;
}

.startScreen h1{
    grid-column:    2 / 3;
    grid-row:       2 / 3;
    align-self:     center;
}

.startParagraph{
    grid-column:    4 / 5;
    grid-row:       3 / 4;
    align-self:     center;
}

.startParagraph h6{
    margin-top:     15px;
}

.startScreen span{
    color:          #000000;
}

.startScreen span:hover{
    cursor:     pointer;
    animation:  colorWheel 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes colorWheel {
    0%      {color: #0204ce}
    35%     {color: #e52e51}
    60%     {color: #f7d845}
    80%     {color: #c42565}
    100%    {color: #0204ce}
}


.followMe{
    grid-column:    1 / 6;
    grid-row:       4 / 5;
    width:          fit-content;
    height:         fit-content;
    justify-self:   center;
    align-self:     end;
    margin-bottom:  30px;
    opacity:        100%;
    transition:     opacity 1s;
}

#hideScroll{
    opacity: 0;
}

.dot{
    width:      1px;
    height:     1px;
    background: #000000;
    margin-top: 12px;

    animation: pulse_dots 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes pulse_dots {
    0%       {box-shadow: 0 0 0 0 rgba(0, 0, 0, 1)}
    50%      {box-shadow: 0 0 0 3px rgba(0, 0, 0, 1)}
    100%     {box-shadow: 0 0 0 0 rgba(0, 0, 0, 1)}
}






@media (max-width: 1400px) {

    .startScreen{
        grid-template-columns:  auto 350px 100px 350px auto;
    }


}

@media (max-width: 1000px) {

    .startScreen{
        grid-template-columns:  auto 250px 100px 250px auto;
    }

}

@media (max-width: 760px) {

    .startScreen{
        grid-template-columns:  10% 30% 50% 10%;
    }

    .startScreen h1{
        grid-column: 2 / 4;
    }

    .startParagraph{
        grid-column: 2 / 4;
        text-align: end;
    }

    .followMe {
        display: none;
    }

}